const tabs = document.querySelectorAll(".service__tab-navigation .tab");
const panes = document.querySelectorAll(
	".service__tab-content .service__tab-pane"
);

tabs.forEach((tab) => {
	tab.addEventListener("click", () => {
		const clickedTab = tab.attributes[1].value;
		tabs.forEach((tab) => tab.classList.remove("tab--active"));
		panes.forEach((pane) => pane.classList.remove("active"));

		tab.classList.add("tab--active");
		panes.forEach((pane) => {
			if (pane.attributes[1].value === clickedTab) {
				pane.classList.add("active");
			}
		});
	});
});
