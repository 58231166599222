const mobileNavIcon = document.querySelector(
	".main-navigation__container__mobile-menu-icon"
);
const navigation = document.querySelector(
	".main-navigation__container__navigation"
);
const navigationItems = document.querySelector(
	".main-navigation__container__navigation a"
);

mobileNavIcon.addEventListener("click", () => {
	navigation.style.display === "none"
		? (navigation.style.display = "block")
		: (navigation.style.display = "none");
});
